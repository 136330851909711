import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`K-means`}</h1>
    <ul>
      <li parentName="ul">{`K-means is a simple cluster analysis algorithm which groups data points so as to minimize the average distance between members of each group to the "mean", or center of mass, of said data points.`}</li>
      <li parentName="ul">{`Running K-means requires setting a value of K (the number of desired clusters) beforehand. In order to select a suitable K value, Polis compute the `}<a parentName="li" {...{
          "href": "/Silhouette-Coefficient",
          "title": "Silhouette Coefficient"
        }}>{`Silhouette Coefficient`}</a>{` (a measure of how well the clustering explains the data) of the K-means clusters for several values of K, and select the optimal K-value according to this metric.`}</li>
      <li parentName="ul">{`See more on wikipedia: `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/K-means_clustering"
        }}>{`https://en.wikipedia.org/wiki/K-means_clustering`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      